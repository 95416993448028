import React from "react";
import { Row, Col } from "reactstrap";
import {MDBCard, MDBCardBody, MDBCardHeader} from "mdb-react-ui-kit";
import { Menu, toaster } from "evergreen-ui";

function Account() {
  function getHeight(){
    return window.innerHeight -100;
} 

function click() {
  toaster.warning("This feature is currently not available contact support for more information");
}
return (
  <div className="content" style={
      { 
      }
  }
 >
      <Row>
          <Col md="12">
              <MDBCard style={
                  {
                    width: "100%",
                    height: getHeight(),

                  }}>
                    <MDBCardHeader>
                      <p
                      style={
                        {
                          fontSize: "1.3rem",
                          fontWeight: "600",
                          opacity: "0.7",
                          marginLeft: "10px",
                        }
                      }
                      >{"Account"}</p>
                    </MDBCardHeader>

                  <MDBCardBody>
                    <Menu>
                        <Menu.Group>
                            <Menu.Item
                            onSelect={click}
                             style={
                                {
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    height: "60px",
                                }
                            }>
                                <div
                                style={
                                    {
                                        fontSize: "15px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500
                                    }
                                }
                                >{"Update Display Name"}</div>
                           
                              
                            </Menu.Item>
                            <Menu.Item style={
                                {
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    height: "60px",
                                }
                            }>
                                <div
                                style={
                                    {
                                        fontSize: "15px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500
                                    }
                                }
                                >{"Change Email"}</div>
                           
                              
                            </Menu.Item>
                            <Menu.Item  style={
                                {
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    height: "60px",
                                }
                            }>
                                <div
                                style={
                                    {
                                        fontSize: "15px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500
                                    }
                                }
                                >{"Change Password"}</div>
                           
                              
                            </Menu.Item>
                            <div style={
                                {
                                    margin: "20px"
                                }
                            }>
                            <Menu.Divider ></Menu.Divider>
                            </div>
                            <Menu.Item intent="danger" style={
                                {
                                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    height: "60px",
                                }
                            }>
                                <div
                                style={
                                    {
                                        fontSize: "15px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500
                                    }
                                }
                                >{"Delete Account"}</div>
                            </Menu.Item>
                        </Menu.Group>
                    </Menu>
                  </MDBCardBody>
                  </MDBCard>
              </Col>
      </Row>
  </div>
);
}

export default Account;
