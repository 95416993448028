/*!

=========================================================
* Paper Dashboard React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/Meg Finance
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/Meg Finance/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./views/Dashboard.js";
import UpgradeToPro from "./views/Upgrade.js";
import Deposit from "./views/Deposit.js";
import Withdraw from "./views/Withdraw.js";
import Transactions from "./views/Transactions.js";
import Account from "./views/Account.js";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/home"
  },
  {
    path: "/deposit",
    name: "Deposit",
    icon: "nc-icon nc-money-coins",
    component: Deposit,
    layout: "/home"
  },

  {
    path: "/withdraw",
    name: "Withdraw",
    icon: "nc-icon nc-money-coins",
    component: Withdraw,
    layout: "/home"
  },

  {
    path: "/transactions",
    name: "Transactions",
    icon: "fas fa-exchange-alt",
    component: Transactions,
    layout: "/home"
  },
  {
    path: "/account",
    name: "Account",
    icon: "nc-icon nc-circle-10",
    component: Account,
    layout: "/home"
  }
  ,
  {
    pro: true,
    path: "/upgrade",
    name: "change plan",
    icon: "nc-icon nc-spaceship",
    component: UpgradeToPro,
    layout: "/home"
  }
];
export default routes;
