/*!

=========================================================
* Paper Dashboard React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/Meg Finance
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/Meg Finance/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import DemoNavbar from "./components/Navbars/DemoNavbar.js";
import Footer from "./components/Footer/Footer.js";
import Sidebar from "./components/Sidebar/Sidebar.js";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { useHistory } from "react-router-dom";
import { getAuth} from 'firebase/auth'
import routes from "./routes.js";
import AdminPanel from "./views/AdminPanel.js";

var ps;

function Dashboard(props) {
  const [backgroundColor, /*setBackgroundColor*/] = React.useState("black");
  const [activeColor, /*setActiveColor*/] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  // const handleActiveClick = (color) => {
  //   setActiveColor(color);
  // };
  // const handleBgClick = (color) => {
  //   setBackgroundColor(color);
  // };
  const history = useHistory();
  const auth = getAuth();
  auth.onAuthStateChanged((user) => {
    if (!user) {
      history.replace("/login");
      document.title = "Meg Finance";
      return;
    }
    document.title = "Meg Finance | " + user?.displayName ;
  });
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} />
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
          <Route path="/home/admin" component={AdminPanel} />
          <Redirect to="/home/dashboard" />
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
}

export default Dashboard;
