import { isUserAdmin, Transaction,} from "../utils/UserData";
import { getAuth } from "firebase/auth";
import React from "react";
import { Spinner,Alert, Button,toaster } from "evergreen-ui";
import { Card,CardHeader,CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { GetPendingTransactions,ConfirmDeposit,GetUsers,ConfirmWithdraw,GetPendingTransactionsW } from "../utils/AdminUtil";

function AdminPanel() {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
const [transactions, setTransactions] = React.useState([Transaction.emtpy()]);
const [transactionsW, setTransactionsW] = React.useState([Transaction.emtpy()]);
const [selected, setSelected] = React.useState(Transaction.emtpy());
const [loadingT, setLoadingT] = React.useState(true);
const [loadingTW, setLoadingTW] = React.useState(true);
const [loadingA, setLoadingA] = React.useState(false);
const [users, setUsers] = React.useState([]);
const [loadingU, setLoadingU] = React.useState(true);
const history = useHistory();

  async function checkAdmin() {
    try {
      const auth = getAuth();
      const uid = auth.currentUser.uid;
      const admin = await isUserAdmin(uid);
      if (!admin) {
        history.replace("/home");
        return;
    }
      setIsAdmin(admin);
      setLoading(false);
      getTransactions();
      getTransactionsW();
      getUsers();
    } catch (_) {
      setTimeout(checkAdmin, 1000);
    }
  }

  async function confirmDeposit(transaction) {
    try {
      setLoadingA(true);
      setSelected(transaction);
      await ConfirmDeposit(transaction);
      setLoadingA(false);
      getTransactions();
      toaster.success("Deposit Confirmed");
    } catch (error) {
        setLoadingA(false);
        toaster.danger("Error Confirming Deposit");
        console.log(error);
    }
  }

  async function confirmWithdraw(transaction) {
    try {
      setLoadingA(true);
      setSelected(transaction);
      await ConfirmWithdraw(transaction);
      setLoadingA(false);
      getTransactionsW();
      toaster.success("Withdraw Confirmed");
    } catch (error) {
        setLoadingA(false);
        toaster.danger("Error Confirming Withdraw");
        console.log(error);
    }
  }

  async function getTransactions() {
    try {
      setLoadingT(true);
      const transactions = await GetPendingTransactions();
      setTransactions(transactions);
      setLoadingT(false);
    } catch (_) {
      setTimeout(getTransactions, 2000);
    }
  }
  
  async function getTransactionsW() {
    try {
      setLoadingTW(true);
      const transactions = await GetPendingTransactionsW();
      setTransactionsW(transactions);
      setLoadingTW(false);
    } catch (_) {
      setTimeout(getTransactionsW, 2000);
    }
  }

  async function getUsers(){
    try {
      setLoadingU(true);
      const users = await GetUsers();
      setUsers(users);
      setLoadingU(false);
    } catch (_) {
      console.log(_);
    }
  }

  React.useEffect(() => {
    checkAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="content">
      {loading ? (
        <center>
            <Spinner />
        </center>
      ) : isAdmin ? (
        <div>
        <p>Admin Panel</p>
        <Card>
            <CardHeader>
                <p>Pending Deposits</p>
            </CardHeader>
            <CardBody>
                {loadingT ? (
                    <center>
                        <Spinner />
                    </center>
                ) : (
                    <div>
                        {transactions.map((transaction) => {
                            return (
                                <Alert style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    fontSize: "12px",
                                }} key={transaction.id} hasIcon={false} intent="info">
                                    <div style={
                                        {
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }
                                    }>
                                        <div style={{
                                            maxWidth: "60%",
                                            overflow: "hidden",
                                        }}>
                                        <div>
                                    Amount: {transaction.amount}
                                    </div>
                                    <div>
                                    coin: {transaction.coin}
                                    </div>
                                    <div style={
                                        {
                                            overflow: "hidden",
                                        }
                                    }>
                                    address: {transaction.address.substring(0, 10) + "..." + transaction.address.substring(transaction.address.length - 10)}
                                    </div>
                                    <div>
                                    date: {transaction.date.toLocaleString()}
                                    </div>
                                    <div>
                                    user: {transaction.uid}
                                    </div>
                                        </div>
                                        <div style={
                                            {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }
                                        }>
                                            <Button intent="info" appearance="primary"
                                            isLoading={loadingA && selected.id === transaction.id}
                                            onClick={() => confirmDeposit(transaction)}
                                            >{loadingA && selected.id === transaction.id? "":"Approve"}</Button>
                                        </div>
                                    </div>
                                    </Alert>
                            )
                        })}
        </div>
                )}
            </CardBody>
        </Card>
        <Card>
            <CardHeader>
                <p>Pending Withdrawals</p>
            </CardHeader>
            <CardBody>
                {loadingTW ? (
                    <center>
                        <Spinner />
                    </center>
                ) : (
                    <div>
                        {transactionsW.map((transaction) => {
                            return (
                                <Alert style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    fontSize: "12px",
                                }} key={transaction.id} hasIcon={false} intent="danger">
                                    <div style={
                                        {
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }
                                    }>
                                        <div style={{
                                            maxWidth: "60%",
                                            overflow: "hidden",
                                        }}>
                                        <div>
                                    Amount: {transaction.amount}
                                    </div>
                                    <div>
                                    coin: {transaction.coin}
                                    </div>
                                    <div style={
                                        {
                                            overflow: "hidden",
                                        }
                                    }>
                                    address: {transaction.address.substring(0, 10) + "..." + transaction.address.substring(transaction.address.length - 10)}
                                    </div>
                                    <div>
                                    date: {transaction.date.toLocaleString()}
                                    </div>
                                    <div>
                                    user: {transaction.uid}
                                    </div>
                                        </div>
                                        <div style={
                                            {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }
                                        }>
                                            <Button intent="danger" appearance="primary"
                                            isLoading={loadingA && selected.id === transaction.id}
                                            onClick={() => confirmWithdraw(transaction)}
                                            >{loadingA && selected.id === transaction.id? "":"Approve"}</Button>
                                        </div>
                                    </div>
                                    </Alert>
                            )
                        })}
        </div>
                )}
            </CardBody>
        </Card>
        <Card>
            <CardHeader>
                <p>All Users</p>
            </CardHeader>
            <CardBody>
                {loadingU ? (
                    <center>
                        <Spinner />
                    </center>
                ) : (
                    <div>
                        {users.map((user) => {
                            return (
                                <Alert style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    fontSize: "12px",
                                }} key={user.uid} hasIcon={false} intent="success">
                                    <div style={
                                        {
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }
                                    }>
                                        <div>
                                        <div>
                                    name: {user.name}
                                    </div>
                                    <div>
                                    balance: {user.balance}
                                    </div>
                                    <div>
                                    plan : {user.plan}
                                    </div>
                                    <div>
                                    uid: {user.uid}
                                    </div>
                                        </div>
                                        <div style={
                                            {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }
                                        }>
                                            <Button intent="danger" appearance="primary"
                                            >{"Terminate"}</Button>
                                        </div>
                                    </div>
                                    </Alert>
                            )
                        })}
        </div>
                )}
            </CardBody>
        </Card>
        </div>
      ) : (
        <p>You are not an admin</p>
      )}
    </div>
  );
}

export default AdminPanel;

/**
 * 
 * @param {String} value 
 * @returns {String}
 */
function convertToString(value) {
  return value.toString();
}