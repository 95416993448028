  import React from "react";
  import { Row, Col } from "reactstrap";
  import {MDBCard, MDBCardBody, MDBCardHeader} from "mdb-react-ui-kit";
  import { Spinner, Alert } from "evergreen-ui";
  import { GetTransactions,Transaction } from "../utils/UserData";
  import { getAuth } from "firebase/auth";
  
function Transactions() {
    const [transactions, setTransactions] = React.useState([Transaction.emtpy()]);
    const [loading, setLoading] = React.useState(true);
 
   async function getTransactions() {
       try {
        const auth = getAuth();
        const uid = auth.currentUser.uid;
        const transactions = await GetTransactions(uid);
        setTransactions(transactions);
        setLoading(false);
       } catch (_) {
        setTimeout(getTransactions, 1000);
       }

    }
    React.useEffect(() => {
        getTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getHeight(){
        return window.innerHeight -100;
    } 
    
  return (
    <div className="content" style={
        {
        }
    }
   >
        <Row>
            <Col md="12">
                <MDBCard style={
                    {
                       // expand the card to the full width of the page
                        width: "100%",
                        height: getHeight(),
                    }}>
                      <MDBCardHeader>
                        <p
                        style={
                          {
                            fontSize: "1.3rem",
                            fontWeight: "500",
                          }
                        }
                        >Transactions</p>
                      </MDBCardHeader>

                    <MDBCardBody style={
                      {
                        overflowY: "scroll",
                        marginBottom: "20px",
                      }
                    }>
                      {
                          loading ? <center> <Spinner size={80}/></center> :
                          transactions.length > 0 ?
                              transactions.map((transaction) => {
                                  return (
                                      <div
                                      style={
                                        {
                                          marginBottom: ".5rem",
                                        }
                                      }
                                       key={transaction.date.getMilliseconds() * transaction.date.getSeconds()}>
                                        <Alert intent={transaction.type === "deposit" ? "success" :
                                        transaction.type === "withdraw"? "danger": "info"} 
                                        title={transaction.type.toUpperCase()}
                                        hasIcon={false}
                                        >
                                            <p style={
                                              {
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                margin : "0px",
                                              }

                                            }>amount: ${transaction.amount}</p>
                                            <p style={
                                              {
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                margin : "0px",
                                              }}
                                            >date: {transaction.date.toLocaleString()}</p>
                                        </Alert>
                                      </div>
                                  );
                              })
                          :
                          <Alert intent="none" title="No transactions found"/>
                      }
                    </MDBCardBody>
                    </MDBCard>
                </Col>
        </Row>
    </div>
  );
}

export default Transactions;
