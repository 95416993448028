import{ React, useState, useRef} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
}
from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { Tape } from '../components/elements/TradeChart';
import Logo from '../components/layout/partials/Logo';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// Import the functions you need from the SDKs you need

import { getAuth, signInWithEmailAndPassword,} from 'firebase/auth'
import { Link, useHistory } from "react-router-dom";


function getOptions (err)  {
  var opt = {
    place: "tr",
    message: (
      <div>
        <div>
         <p>{err}</p>
        </div>
      </div>
    ),
    type: "danger",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 7
  }
  return opt;
}


function App() {
  const notificationAlert = useRef();
  const history = useHistory();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      history.replace("/home");
    }
  });
  function login() {
    const auth = getAuth();
    // get the email and password from the form
    const e = document.getElementById("form3").value;
    const p = document.getElementById("form4").value;
    console.log(e);
    setLoading(true);
    signInWithEmailAndPassword(auth, e, p).finally(() => {
      setLoading(false);
    })
    .catch((error) => {
      var e = "unknown error";

      switch (error.code) {
        case "auth/invalid-email":
          e = "Invalid email";
          break;
        case "auth/user-disabled":
          e = "User disabled";
          break;
        case "auth/user-not-found":
          e = "User not found";
          break;
        case "auth/wrong-password":
          e = "Wrong password";
          break;
        default:
          break;
      }
      notificationAlert.current.notificationAlert(getOptions(e));
    });
  }
  
  let body = <>
  <MDBInput wrapperClass='mb-4' label='Email' id='form3' type='email'/>
                <MDBInput wrapperClass='mb-4' label='Password' id='form4' type='password'/>
                <MDBBtn className='w-100 mb-4' size='md' disabled={loading} onClick={login}>{loading? "authenticating":"login"}</MDBBtn>
                <div>
                  <p style={
                  {
                    fontSize: "12px",
                  }
                }
                className='d-flex justify-content-end'>
                     <Link to='/forgot-password' style={
                        {
                            color: "#1266f1",
                            cursor: "pointer",
                        }} >forgot password?</Link>
                  </p>
                </div>
                <center>
                <p style={
                  {
                    fontSize: "15px",
                  }
                }>
                  Don't have an account? <Link to='/signup' style={
                        {
                            color: "#1266f1",
                            cursor: "pointer",
                        }} className='font-weight-bold ml-1'> {"SignUp".toUpperCase()}</Link>
                </p>
                </center>
                <div className="text-center">
                  <p>or sign up with:</p>
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                    <MDBIcon fab icon='facebook-f' size="sm"/>
                  </MDBBtn>
  
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                    <MDBIcon fab icon='twitter' size="sm"/>
                  </MDBBtn>
  
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                    <MDBIcon fab icon='google' size="sm"/>
                  </MDBBtn>
  
                  <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                    <MDBIcon fab icon='github' size="sm"/>
                  </MDBBtn>
  
                </div>
  </>

  if(window.innerWidth < 768) {
    return (
      <>
      <NotificationAlert ref={notificationAlert} />
      <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>
  
        <MDBRow>
          <MDBCol md='6' className='position-relative'>
            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
            <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
  
            <MDBCard className='my-5 bg-glass'>
              <MDBCardBody className='p-3'>
              <div style={
              {
             display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
              }
            }>
              <Logo />
            </div>
                {body}
              </MDBCardBody>
            </MDBCard>
  
          </MDBCol>
  
        </MDBRow>
        <Tape></Tape>
      </MDBContainer>
      </>
    );
  }
  return (
    <>
    <NotificationAlert ref={notificationAlert} />
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

      <MDBRow>

        <MDBCol md='6' className='text-center text-md-start d-flex flex-column justify-content-center'>

          <h1 className="my-5 display-10 fw-bold ls-tight px-3" style={{color: 'hsl(218, 81%, 95%)'}}>
            <span style={{color: 'hsl(218, 81%, 75%)'}}>Meg Finance</span><br />
            The true path to Financial Freedom
          </h1>

        </MDBCol>

        <MDBCol md='6' className='position-relative'>

          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
              {body}

            </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>
      <Tape></Tape>
    </MDBContainer>
    </>
  );
}

export default App;