import{ React, useState, useRef} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
}
from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { Tape } from '../components/elements/TradeChart';
import Logo from '../components/layout/partials/Logo';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { getAuth, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth'
import { Link, useHistory } from "react-router-dom";
import { CreateUser, addReferral } from '../utils/UserData';
import { useLocation } from 'react-router-dom';
import { sendMailForReg } from '../utils/Mailer';


function getOptions (err)  {
  var opt = {
    place: "tr",
    message: (
      <div>
        <div>
         <p>{err}</p>
        </div>
      </div>
    ),
    type: "danger",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 7
  }
  return opt;
}



function App() {
  const notificationAlert = useRef();
  const history = useHistory();
  const location = useLocation();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  let isSmall = window.innerWidth < 768;
  function getStyle() {
    if(isSmall){
        return {
            fontSize: "15px",
            padding: "15px",
        };
    }
}
  let body = <div style={getStyle()}>
            <MDBInput wrapperClass='mb-4' label='Email' id='form3' type='email'/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form4' type='password'/>
            <MDBInput wrapperClass='mb-4' label='Confirm Password' id='form5' type='password'/>
              <MDBInput wrapperClass='mb-4' label='Display Name' id='form6' type='text'/>
             
              <MDBBtn className='w-100 mb-4' size='md' disabled={loading} onClick={signup} >{loading? "registering":"signup"}</MDBBtn>
              <p className="text-center">
                    {"already have an account? "}
                    <Link style={
                        {
                            color: "#1266f1",
                            cursor: "pointer",
                        }
                    } to="/login" className='font-weight-bold ml-1'>{"login".toUpperCase()}</Link>
                </p>
              <div className="text-center">

                <p>or sign up with:</p>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='facebook-f' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='twitter' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='google' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='mx-3' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='github' size="sm"/>
                </MDBBtn>
              </div>
  </div>;
  function signup() {
    // get the email and password from the form
    const e = document.getElementById("form3").value;
    const p = document.getElementById("form4").value;
    const cp = document.getElementById("form5").value;
    const dn = document.getElementById("form6").value;
    if(p !== cp){
        notificationAlert.current.notificationAlert(getOptions("passwords do not match"));
        return;
    }
    if(dn === ""){
        notificationAlert.current.notificationAlert(getOptions("display name is required"));
        return;
    }
    setLoading(true);
    createUserWithEmailAndPassword(auth, e, p).then(async(_)=> {
        await updateProfile(auth.currentUser, {
            displayName: dn,
        });
        const user = auth.currentUser;
        await CreateUser({name: user.displayName, balance: 0, plan:"none", uid: user.uid });
        const query = new URLSearchParams(location.search);
        const ref = query.get("ref");
        if(ref){
            await addReferral(user.uid, ref);
        }
        sendMailForReg(user.email, user.displayName);
        history.push("/home");
    })
    .catch((error) => {
      var e = "unknown error";
      console.log(error);
      switch (error.code) {
        case "auth/email-already-in-use":
            e = "email already used by another account";
            break;
        case "auth/invalid-email":
            e = "invalid email format";
            break;
        case "auth/operation-not-allowed":
            e = "internal error";
            break;
        case "auth/weak-password":
            e = "password is weak (must be at least 6 characters)";
            break;
        default:
          break;
      }
      notificationAlert.current.notificationAlert(getOptions(e));
      setLoading(false);
    });
  }
  
  if(window.innerWidth < 768) {
    return (
      <>
      <NotificationAlert ref={notificationAlert} />
      <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>
  
        <MDBRow>
          <MDBCol md='6' className='position-relative'>
            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
            <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
  
            <MDBCard className='my-5 bg-glass'>
              <MDBCardBody className='p-3'>
              <div style={
              {
             display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
              }
            }>
              <Logo />
            </div>
                {body}
              </MDBCardBody>
            </MDBCard>
  
          </MDBCol>
  
        </MDBRow>
        <Tape></Tape>
      </MDBContainer>
      </>
    );
  }
  return (
    <>
    <NotificationAlert ref={notificationAlert} />
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

      <MDBRow>

        <MDBCol md='6' className='text-center text-md-start d-flex flex-column justify-content-center'>

          <h1 className="my-5 display-10 fw-bold ls-tight px-3" style={{color: 'hsl(218, 81%, 95%)'}}>
            <span style={{color: 'hsl(218, 81%, 75%)'}}>Meg Finance</span><br />
            The true path to Financial Freedom
          </h1>

        </MDBCol>

        <MDBCol md='6' className='position-relative'>

          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
            {body}
            </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>
      <Tape></Tape>
    </MDBContainer>
    </>
  );
}

export default App;