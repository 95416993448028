import React from "react";
import { MDBContainer,MDBCard, MDBCardBody, MDBCardFooter} from "mdb-react-ui-kit";
import {SelectMenu, Button, Spinner, TextInput, InlineAlert,Alert, toaster} from "evergreen-ui";
import { useLocation,useHistory } from "react-router-dom";
import {getAuth} from "firebase/auth"
import { Row, Col } from "reactstrap";
import { ProccessDeposit } from "../utils/UserData";
import { getCoins, Coin } from "../variables/coins";
import { sendMailForTransaction } from "../utils/Mailer";

const borderColor = "rgba(0, 0, 0, 0.2)";


function DepositMoney () {
    const [selected, setSelected] = React.useState(null);
    const [amount, setAmount] = React.useState(0);
    const [status, setStatus] = React.useState("please select a coin");
    const [loading, setLoading] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [coins, setCoins] = React.useState([Coin.emtpy()]);
    const [done, setDone] = React.useState(false);
    const location = useLocation();
    const history = useHistory();
    const auth =  getAuth();
    auth.onAuthStateChanged((user) => {
        if (!user) {
            history.push("/login");
        }
    });
    React.useEffect( () => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function deposit() {
        setLoading(true);
        const uid = auth.currentUser.uid;
        await ProccessDeposit(uid, amount, selected.address, selected.symbol);
        const email = auth.currentUser.email;
        const name = auth.currentUser.displayName;
        sendMailForTransaction(email, name, amount, "Deposit", "Processing");
        setDone(true);
        setStatus("Deposit proccessed");
        setLoading(false);
    }
  
     function init(){
        const query = new URLSearchParams(location.search);
        const amount = query.get("amount") * 1;
        if(amount > 0){
            setAmount(amount);
            load();
        }else{
            history.replace("/home/deposit");
        }
    }

    async function load(){
        const res = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,bitcoin,litecoin,tron,ethereum&vs_currencies=usd");
        const prices = await res.json();
        const c = getCoins(prices);
        setCoins(c);
        setLoaded(true);
        toaster.notify("Please select a coin to deposit");
    }

    function onCopy(){
        setCopied(true);
        toaster.success("Address copied to clipboard");
    }
    
    async function copyAddress(){
        try {
            await navigator.clipboard.writeText(selected.address);
            setCopied(true);
            const a = (amount / selected.price).toFixed(3);
            toaster.success("Address copied to clipboard",
            {
                description: "Please send "+a+ " " +selected.symbol+
                " to this address to complete your deposit",
                duration: 10,
            }
            );
        } catch (error) {
            toaster.danger(error.message);
            setCopied(true);
        }
    }
 const isSmall = window.innerWidth < 768;
  return (
    <MDBContainer fluid style={{
        //expand to fill the screen
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        //center the content
        display: "flex",
        justifyContent: "center",
    }} className='p-3 background-radial-gradient overflow-hidden money'> 
      
            <div 
            style={{
                //center the content
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                //make the content responsive
                width: "100%",
                height: "100%",
                //add some padding
                padding: "0px",
            }}>
                <MDBCard style={
                isSmall ? {
                    width: "100%",
                    height: "70%",
                    }: {
                        width: "400px",
                        height: "600px",
                        }
                } className='my-5 ' >
                        <center>
                        <h3 className='black-text'>Secure Deposit</h3>
                        </center>
               { !loaded ?
               <div 
               style={
                {
                    //center the content
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    //make the content responsive
                    width: "100%",
                    height: "100%",
                }
               }
               >
                   <Spinner size={80} marginBottom={40}/>
               </div>
                 :<>
                  {done ? <div style={
                    {
                        //center the content
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        height: "60%",
                    }
                   } className='text-center'>
                       <i className="fas fa-check-circle fa-5x green-icon"></i>
                        <Alert margin={30} intent="success" title="Your deposit is been processed" >
                            </Alert>
                            <Button onClick={() => history.push("/home")} appearance="primary" intent="success" >Go Home</Button>
                            </div> : <MDBCardBody
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                    }}
                    >
                        <Row>
                            <Col>
                            <p className='black-text2'>amount($): </p>
                            </Col>
                            <Col>
                    <Alert style={
                        {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            textAlign: "right",
                            justifyContent: "center",
                        alignItems: "center",
                        borderColor: borderColor,
                        borderRadius: "5px",
                        
                        }
                       } intent="none" hasIcon={false} height={20}
                       marginBottom={20}
                       >
                        <Row>
                            <Col>
                            <div className="amount">
                            {amount}
                            </div>
                            </Col>
                            </Row> 
                            </Alert>
                            </Col>
                            </Row>
                        <Row>
                            <Col>
                            <p className='black-text2'>{selected? 
                            selected.symbol
                             : "coin"}: </p>
                            </Col>
                            <Col>
                    {!selected? <Spinner size={30} margin={0} />:
                        <Alert style={
                        {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            textAlign: "right",
                            justifyContent: "center",
                        alignItems: "center",
                        borderColor: borderColor,
                        borderRadius: "5px",
                        
                        }
                       } intent="none" hasIcon={false} height={20}
                       marginBottom={20}
                       >
                        <Row>
                            <Col>
                            <div className="amount">
                            {(amount / selected.price).toFixed(3)}
                            </div>
                            </Col>
                            </Row> 
                            </Alert>}
                            </Col>
                            </Row>
                            <Row>
                            <Col>
                            <p className='black-text2'>address: </p>
                            </Col>
                            <Col>
                        {
                            !selected? <Spinner size={30} margin={0} />:
                                <div style={
                                    {
                                        // align horizontally
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                    }
                                }>
                                <TextInput 
                                readOnly
                                style={{
                                    width: "100%",
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    // add color to border
                                    borderColor: borderColor,
                                    borderRight: "none",
                                    borderTopRightRadius: "0",
                                    borderBottomRightRadius: "0",
                                    borderTopLeftRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                  }}
                                  onCopy={onCopy}
                                 value={selected.address}>
                                 </TextInput>
                                 <Button 
                                 style={{
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    borderColor: borderColor,
                                    borderLeft: "none",
                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    borderTopRightRadius: "5px",
                                    borderBottomRightRadius: "5px",
                                  }}
                                     onClick={copyAddress}>
                                    <i className="fas fa-copy"></i>
                                 </Button>
                            </div>
                                
                        }
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                            <p className='black-text2'>coin: </p>
                            </Col>
                            <Col>
                    <SelectMenu 
                    closeOnSelect={true}
                 title="Select Coin"
                 filterPlaceholder="Search Coins"
                 options={ coins.map((c) => ({ label: c.symbol, value: c.name }))}
                 selected={selected == null? null: selected.name}
                 onSelect={(item) => {
                    const c = coins.find((c) => c.name === item.value);
                    setSelected(c);
                    setStatus("waiting for deposit");
                toaster.notify("Address generated for "+item.label,{
                    description: "Please copy and send your deposit to the following address",
                });
                }}
    >
      <Button style={{
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: borderColor,
        borderRadius: "5px",
      }}>{selected == null? "Select Coin": selected.symbol}</Button>
    </SelectMenu>
                            </Col>
                            </Row>

                <center className="contact">
                    Having trouble? <a className="link" href="mailto:support@wackinvest.com"> contact support</a>
                </center>
                <center className="terms2">
                    <p className="black-text">
                        by click Confirm Deposit you agree to our <a className="link" href="/terms">Terms of Service</a>  and have transfered the correct amount to the address above
                    </p>
                </center>
                <center>
                    <div style={
                        {
                            marginTop: "10px",
                        }
                    }>
                    <Button
                    disabled= {!copied}
                    onClick={deposit}
                    isLoading={loading}
                    appearance="primary" intent="info">
                        {"Confirm Deposit"}
                    </Button>
                    </div>
                </center>

                </MDBCardBody>}
                </>}
               { !loaded? null:<MDBCardFooter>
                <center>
                                 <InlineAlert  intent="none" marginTop={16}
                                 // center the content
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                 >
                                   {status}
                                 </InlineAlert>
                                 </center>
                </MDBCardFooter>}
                </MDBCard>
                </div>
        </MDBContainer>
  );
}

export default DepositMoney;