import { TickerTape,TechnicalAnalysis,AdvancedRealTimeChart,MiniChart } from "react-ts-tradingview-widgets";

//const symbols = ['BTCUSD', 'ETHUSD', 'BNBUSD', 'XRPUSD', 'SOLUSD', 'FTMUSD'];
// const footerSymbols = [
//   {
//     "proName": "BITSTAMP:ETHUSD",
//     "title": "ETH/USD"
//   },
//   {
//     "proName": "BITSTAMP:XRPUSD",
//     "title": "XRP/USD"
//   },
//   {
//     "proName": "BITSTAMP:FTMUSD",
//     "title": "FTM/USD"
//   },
//   {
//     "proName": "BITSTAMP:BTCUSD",
//     "title": "BTC/USD"
//   },
//   {
//     "proName": "BITSTAMP:SOLUSD",
//     "title": "SOL/USD"
//   },
// ]
const Tape = () => (
  <TickerTape colorTheme="dark" isTransparent ="true"></TickerTape>
  );

  const BitcoinChart = () => (
    <MiniChart width={600} symbol ={'BTCUSD'} colorTheme="light" allow_symbol_change height={500}></MiniChart>
  );

  const TA = () => (
    <TechnicalAnalysis  symbol="BTCUSD" interval="1D" width={300} colorTheme="light" isTransparent></TechnicalAnalysis>
  )

  const TapeFooter = () => (
    <TickerTape colorTheme="light" isTransparent ="true"></TickerTape>
  )
  export {Tape, BitcoinChart, TA, TapeFooter};
