const myHeaders = new Headers();
myHeaders.append("accept", "application/json");
myHeaders.append("api-key", "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");
myHeaders.append("content-type", "application/json");

const url = "https://api.brevo.com/v3/smtp/email";

export const  sendMailForReg = async (email, name) => {
    var raw = JSON.stringify({
        "sender": {
          "name": "Meg Finance",
          "email": "support@meg-finance.org"
        },
        "to": [
          {
            "email": email,
            "name": name
          }
        ],
        "templateId": 1,
        "params": {
          "name": name,
        }
      });
    
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

        try {
            const response = await fetch(url, requestOptions);
            if(response.status === 201){
                console.log("mail sent")
            }else{
                console.log("mail not sent")
            }
        }  catch (error) { 
            console.log(error);
        }
}

export const  sendMailForTransaction = async (email, name, amount, type, status) => {
    var raw = JSON.stringify({
        "sender": {
          "name": "Meg Finance",
          "email": "support@meg-finance.org"
        },
        "to": [
          {
            "email": email,
            "name": name
          }
        ],
        "templateId": 2,
        "params": {
          "name": name,
          "type": type,
          "amount": amount,
          "status": status
        }
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      try {
        const response = await fetch(url, requestOptions);
        if(response.status === 201){
            console.log("mail sent")
        }else{
            console.log("mail not sent")
        }
    }  catch (error) { 
        console.log(error);
    }
}