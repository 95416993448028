import {getFirestore,doc,updateDoc, getDocs,where,query,
    collection,
    orderBy,
} from "firebase/firestore";
import { Transaction,AddUserBalance,UserData } from "./UserData";

async function GetPendingTransactions(){
    let  arr = [];
    const db = getFirestore();
    const q = query(collection(db, "transactions"),
    orderBy("date", "desc"),
     where("type", "==", "proccessing"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        arr.push(Transaction.fromDoc(doc.data()));
    });
    return arr;
    
}

async function GetPendingTransactionsW(){
    let  arr = [];
    const db = getFirestore();
    const q = query(collection(db, "transactions"),
    orderBy("date", "desc"),
     where("type", "==", "pending widrawal"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        arr.push(Transaction.fromDoc(doc.data()));
    });
    return arr;
    
}

async function setTransactionType(id, type){
    const db = getFirestore();
    const docRef = doc(db, "transactions", id);
    await updateDoc(docRef, {
        type: type,
    });
}

/**
 * 
 * @param {Transaction} transaction 
 */
async function ConfirmDeposit(transaction){
    const uid = transaction.uid;
    const amount = transaction.amount;
    await AddUserBalance(uid, amount);
    await setTransactionType(transaction.id, "deposit");
}

async function ConfirmWithdraw(transaction){
    await setTransactionType(transaction.id, "withdraw");
}

async function GetUsers(){
    const  arr = [];
    const db = getFirestore();
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        arr.push(UserData.fromDoc(doc));
    });
    return arr;
    
}

export {GetPendingTransactions, GetPendingTransactionsW, ConfirmDeposit, ConfirmWithdraw, GetUsers};

