import React from "react";
import { ChangeUserPlan } from "../utils/UserData";
import { getAuth } from "firebase/auth";
import {
  Row,
  Col
} from "reactstrap";
import { MDBCard,
  MDBCardBody,
  MDBCardHeader,
 } from "mdb-react-ui-kit";

 import { Button, toaster, Dialog,TextInput} from "evergreen-ui";

const plans = [ 
  {
    name: "Basic",
    min: 50,
    max: 499,
    duration: "24 hours",
    Profits: 15,
    referralCommission: 5,
  },
  {
    name: "Standard",
    min: 500,
    max: 1999,
    duration: "48 hours",
    Profits: 35,
    referralCommission: 5,
  },
  {
    name: "Premium",
    min: 2000,
    max: 4999,
    duration: "72 hours",
    Profits: 50,
    referralCommission: 10,
  },
  {
    name: "Vip",
    min: 5000,
    max: 10000,
    duration: "4 days",
    Profits: 75,
    referralCommission: 10,
  },
  {
    name: "Unlimited",
    min: 10000,
    max: Number.POSITIVE_INFINITY,
    duration: "5 days",
    Profits: 95,
    referralCommission: 20,
  },
  {
    name: "Long Term/Fixed",
    min: 1000,
    max: Number.POSITIVE_INFINITY,
    duration: "1 month - 12 months",
    Profits: 20,
    referralCommission: 10,
  },
];


function Upgrade() {
  const [loading, setLoading] = React.useState(false);
  const [isShown, setIsShown] = React.useState(false)
  const [selected, setSelected] = React.useState(plans[0]);
  const [price, setPrice] = React.useState(0);
  async function subcribe(plan, amount){
    try {
      amount = amount * 1;
      if(amount < plan.min){
        toaster.danger("Amount cannot be less than " + plan.min);
        return;
      }
      if(plan.max > 0 && amount > plan.max){
        toaster.danger("Amount cannot be more than " + plan.max);
        return;
      }
    setIsShown(false);
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user.uid;
    const price = amount * 1;
    const planName = plan.name;
    await ChangeUserPlan(uid, planName, price);
    toaster.success("You have successfully subscribed to the " + planName + " plan for $" + price + "");
    } catch (error) {
      toaster.danger("Something went wrong", {description: error.message});
    }finally{
      setLoading(false);
    }
    
  }
  function show(plan){
    setSelected(plan);
    setPrice(plan.min);
    setIsShown(true);
  }
  function onChange(e){
    var value = e.target.value * 1;
    if(value >= 0)
    {
      setPrice(value);
    }
  }
  return (
    <>
    <Dialog
        isShown={isShown}
        title="Subscribe to plan"
        onCloseComplete={() => setIsShown(false)}
        confirmLabel="Subscribe"
        onConfirm={() => subcribe(selected,price)}
      >
        <p>
          Enter amount to subscribe to {selected.name} plan
        </p>
        <TextInput
          label="Amount"
          placeholder="Enter amount"
          value={price}
          onChange={onChange}
        />
      </Dialog>
      <div className="content">
        <div style={
          { 
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '100px',
          }
        } className = "plan" >
        <Row>
          {plans.map((plan, index) => 
            <Col lg="4" key={index} md="4">
              <MDBCard className="p-card" style={{ width: '100%', height :"500px"}}>
                <div className="pdiv">
                <MDBCardHeader className="ptitle">{plan.name}</MDBCardHeader>
                <MDBCardBody>
                  <p className="p-dailyProfits">
                     Minimum:
                    <span> ${plan.min}</span>
                  </p>
                  <p className="p-dailyProfits">
                     Maximum:
                    <span> {plan.max === Number.POSITIVE_INFINITY? "Unlimited":("$" +plan.max)}</span>
                  </p>
                  <p className="p-dailyProfits">
                    Duration:
                    <span> {plan.duration}</span>
                  </p>
                  <p>
                    <span className="p-dailyProfits">Profits: <span>{plan.Profits}%</span></span>
                  </p>
                  <p className="p-dailyProfits">
                    Referral Commission: <span>{plan.referralCommission}%</span>
                  </p>
                </MDBCardBody>
                <div className="pfooter">
                  <Button className="pbutton"
                  isLoading={loading && selected.name === plan.name}
                   onClick={ 
                    () => show(plan)
                  }>{loading && selected.name === plan.name? "":"Subscribe"}</Button>
                </div>
                </div>
              </MDBCard>
            </Col>
          )  
        }
        </Row>
        </div>
      </div>
    </>
  );
}

export default Upgrade;
export {plans};
