import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { plans } from '../../views/Upgrade';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const PlanSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Investment Plans',
    paragraph: 'Choose the plan that best suits your needs.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {plans.map((plan, index) => (
                    <div key={index+plan.name} className="tiles-item reveal-from-right" data-reveal-delay="200">
                    <div className="tiles-item-inner">
                      <div >
                        <p className="mb-0" style={
                            {fontWeight: 'bold'}
                        }>
                            <span className="text-color-high">{plan.name}</span>
                        </p>
                           <div>
                            <p className="text-xs mb-0">
                                Minimum Deposit: <span className="text-color-high">${plan.min}</span>
                            </p>
                            <p className="text-xs mb-0">
                                Maximum Deposit: <span className="text-color-high">{plan.max === Number.POSITIVE_INFINITY? "Unlimited":("$" +plan.max)}</span>
                            </p>
                            <p className="text-xs mb-0">
                                Profit: <span className="text-color-high">{plan.Profits}%</span>
                            </p>
                            <p className="text-xs mb-0">
                                Duration: <span className="text-color-high">{plan.duration}</span>
                            </p>
                            <p className="text-xs mb-0">
                                Referral Bonus: <span className="text-color-high">{plan.referralCommission}%</span>
                            </p>
                        
                           </div>
                      </div>
                      <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                        <span className="testimonial-item-link">
                          <Link to="/home/upgrade">Invest</Link>
                        </span>
                      </div>
                    </div>
                  </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

PlanSplit.propTypes = propTypes;
PlanSplit.defaultProps = defaultProps;

export default PlanSplit;