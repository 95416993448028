import {setDoc, getFirestore,doc, getDoc,updateDoc,
    Timestamp, getDocs,where,query,
    collection,
    orderBy,
    arrayUnion,
} from "firebase/firestore";
import {updateProfile,updateEmail,updatePassword, } from "firebase/auth";

class UserData{
    constructor({name, balance, plan, uid}){
        this.name = name;
        this.balance = balance * 1;
        this.plan = plan;
        this.uid = uid;
    } 

    static fromDoc(doc){
        return new UserData(doc.data());
    }

    static emtpy(){
        return new UserData({
            name: "",
            balance: 0,
            plan: "none",
            uid: ""
        });
    };

  toDoc() {
        return {
            name: this.name,
            balance: this.balance,
            plan: this.plan,
            uid: this.uid
        }
    }
 }


 class Transaction {

    constructor({amount, date, type, address,id,coin,uid}){
        this.amount = amount;
        this.date = new Date(date);
        this.type = type;
        this.address = address;
        this.id = id;
        this.coin = coin;
        this.uid = uid;
    }

    static fromDoc(doc){
        return new Transaction({
            amount: doc.amount,
            date: convertToDate(doc.date).toDate(),
            type: doc.type,
            address: doc.address,
            id: doc.id,
            coin: doc.coin,
            uid: doc.uid
        });
    }

    static emtpy(){
        return new Transaction({
            amount: 0,
            date: "",
            type: "",
            address: "",
            id: "",
            coin: "",
            uid: ""
        });
    };

    toDoc() {
        return {
            amount: this.amount,
            date: Timestamp.fromDate(this.date),
            type: this.type,
            address: this.address,
            id: this.id,
            coin: this.coin,
            uid: this.uid
        }
    }
 }

 async function SetUserData( {name, balance, plan, uid}){
    const db = getFirestore();
    const d = new UserData({name, balance, plan, uid}).toDoc();
    await setDoc(doc(db, "users", uid), d);
 }

 export async function CreateUser( {name, balance, plan, uid}){
    const db = getFirestore();
    const d = new UserData({name, balance, plan, uid}).toDoc();
    await setDoc(doc(db, "users", uid), d);
    await setDoc(doc(db, "ref", uid), {
        uid: uid,
        referredBy: "none",
        referredUsers: []
    });
 }

  export async function addReferral(uid, referrer){
    const db = getFirestore();
    const ref = doc(db, "ref", uid);
    const referrerRef = doc(db, "ref", referrer);
    await updateDoc(ref, {
        referredBy: referrer
    });
    try {
        await updateDoc(referrerRef, {
            referredUsers: arrayUnion(uid)
        });
    } catch (error) {
        await setDoc(referrerRef, {
            uid: referrer,
            referredBy: "none",
            referredUsers: [uid]
        });
    }
  }

  export class ReferralData {
    constructor({uid, referredBy, referredUsers}){
        this.uid = uid;
        this.referredBy = referredBy;
        this.referredUsers = convertToArray(referredUsers);
    }
    
    static fromDoc(doc){
        return new ReferralData(doc.data());
    }

    static emtpy(){
        return new ReferralData({
            uid: "",
            referredBy: "",
            referredUsers: []
        });
    };

    toDoc() {
        return {
            uid: this.uid,
            referredBy: this.referredBy,
            referredUsers: this.referredUsers
        }
    }
    }

    export async function GetReferralData(uid){
        const db = getFirestore();
        const docSnap = await getDoc(doc(db, "ref", uid));
        if (docSnap.exists()) {
            return ReferralData.fromDoc(docSnap);
        } else {
            return null;
        }
    }

    async function GetUserData(uid){
        const db = getFirestore();
        const docSnap = await getDoc(doc(db, "users", uid));
        if (docSnap.exists()) {
            return UserData.fromDoc(docSnap);
        } else {
            return null;
        }
    }

    async function UpdateUserName(uid, name, user){
        const db = getFirestore();
        await updateProfile(user, {
            displayName: name
        });
        await updateDoc(doc(db, "users", uid), {
            name: name
        });
    }

    async function UpdateUserPlan(uid, plan){
        const db = getFirestore();
        await updateDoc(doc(db, "users", uid), {
            plan: plan
        });
    }

    async function UpdateUserEmail(uid, email, user){
        await updateEmail(user, email);
    }

    async function UpdateUserPassword(uid, password, user){
        await updatePassword(user, password);
    }


    async function GetUserBalance(uid){
        const user = await GetUserData(uid);
        if(user == null){
            return 0;
        }
        return user.balance;
    }

    async function AddUserBalance(uid, amount){
        const user = await GetUserData(uid);
        if(user == null){
            throw new Error("User does not exist");
        }
        user.balance += amount;
        await SetUserData(user);
    }

    async function ProccessDeposit(uid, amount, address, coin){
        const transaction = new Transaction({
            amount: amount,
            date: new Date(),
            type: "proccessing",
            address: address,
            coin: coin,
            id: generateId(uid)+ Date.now(),
            uid: uid
        });
        await AddTransaction(transaction);
    }

    async function ProccessWithdrawal(uid, amount, address, coin){
        await SubtractUserBalance(uid, amount);
        const transaction = new Transaction({
            amount: amount,
            date: new Date(),
            type: "pending widrawal",
            address: address,
            coin: coin,
            id: generateId(uid)+ Date.now(),
            uid: uid
        });
        await AddTransaction(transaction);
    }

    async function SubtractUserBalance(uid, amount){
        const user = await GetUserData(uid);
        if(user == null){
            throw new Error("User does not exist");
        }
        if(user.balance < amount){
            throw new Error("Insufficient funds");
        }
        user.balance -= amount;
        await SetUserData(user);
    }

    async function GetWithdrawAddresses(uid){
        const arr = [];
        const db = getFirestore();
        const docSnap = await getDoc(doc(db, "userAddress", uid));
        if (docSnap.exists()) {
            const dat = docSnap.data().addresses;
            arr.push(...dat);
        } 

        return arr;
        
    }

    async function AddWithdrawAddress(uid, address){
        const db = getFirestore();
        let arr = await GetWithdrawAddresses(uid);
        if(arr.includes(address)){
            return;
        }
        arr.push(address);
       await setDoc(doc(db, "userAddress", uid), {
            addresses: arr
        });
    }
   
    /**
     * 
     * @param {String} uid 
     * @returns {Promise<Transaction[]>}
     */
    async function GetTransactions(uid){
        let  arr = [];
        const db = getFirestore();
        const q = query(collection(db, "transactions"),orderBy("date", "desc"), where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(Transaction.fromDoc(doc.data()));
        });
        return arr;
        
    }

    async function ChangeUserPlan(uid, plan, price){
        const user = await GetUserData(uid);
        if(user == null){
            throw new Error("User does not exist");
        }
        if(user.balance < price){
            throw new Error("Insufficient funds deposit more funds and try again");
        }
        user.balance -= price;
        user.plan = plan;
        const transaction = new Transaction({
            amount: price,
            date: new Date(),
            type: "Plan Subscription",
            address: "",
            coin: "",
            id: generateId(uid) + Date.now(),
            uid: uid
        });
        await SetUserData(user);
        await AddTransaction(transaction);
    }

    /**
     * 
     * @param {String} uid 
     * @param {Transaction} transaction 
     */
    async function AddTransaction(transaction){
        const db = getFirestore();
        await setDoc(doc(db, "transactions", transaction.id), transaction.toDoc());
    }

    function generateId(uid){
        return Math.random().toString(36).substring(2, 9) + uid;
    }

    /**
     * 
     * @param {String} uid 
     * @returns {Promise<Boolean>}
     */
    async function isUserAdmin(uid){
        try {
            const db = getFirestore();
            const docSnap = await getDoc(doc(db, "admins", uid));
            return docSnap.exists();
        } catch (_) {
            return false;
        }
    }


export {SetUserData, GetUserData,
     UserData , GetWithdrawAddresses,
     AddWithdrawAddress, GetUserBalance,
     AddUserBalance, SubtractUserBalance,
     GetTransactions, AddTransaction,
     Transaction, 
     UpdateUserName, UpdateUserPlan,
     UpdateUserEmail, UpdateUserPassword,
        ProccessDeposit, ProccessWithdrawal,
         ChangeUserPlan, 
        isUserAdmin};
    /**
     * 
     * @param {Timestamp} date 
     * @returns {Timestamp}
     */
    function convertToDate(date){
        return date;
    }

    /**
     * 
     * @param {Array} arr 
     * @returns {Array}
     */
    function convertToArray(arr){
        return arr;
    }