import React from "react";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import {SelectMenu, Spinner, toaster,Button, Dialog,TextInput} from "evergreen-ui";
import { GetWithdrawAddresses,AddWithdrawAddress,GetUserBalance } from "../utils/UserData";
import {
    Row,
    Col
  } from "reactstrap";
  import {
      MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBInput,
  }
  from 'mdb-react-ui-kit';
import { getAuth } from "firebase/auth";
  

function Withdraw() {
    const history = useHistory();
    const notificationAlert = React.useRef();
    const [loaded, setLoaded] = React.useState(false);
    const [addresses, setAddresses] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    const [showDialog, setShowDialog] = React.useState(false);
    const [balance, setBalance] = React.useState(0);
   
    async function withdraw() {
      if(validate()){
        const b = document.getElementById("with1").value * 1;
        const url = "/money/withdraw?amount=" + b + "&address=" + selected;
        history.push(url);
    }
    }

    function validate () {
        if(selected == null){
            toaster.danger("Please select an address");
            return false;
          }
            const amount = document.getElementById("with1").value * 1;
            if(!(amount > 0)){
                return false;
            }
            if(amount > balance){
              document.getElementById("with1").value = 0;
              toaster.danger("You do not have enough money to withdraw that much");
              return false;
            }
            return true;
    }

    async function getAddresses() {
       try {
        setLoaded(false);
        const auth = getAuth();
        const user = auth.currentUser;
        const data = await GetWithdrawAddresses(user.uid);
        const b = await GetUserBalance(user.uid);
        setAddresses(data);
        setBalance(b);
        setLoaded(true);
       } catch (_) {
        setTimeout(getAddresses, 1000);
       }
    }

    async function addAddress(close) {
        const address = document.getElementById("address1").value;
        if(address.length < 1){
            return;
        }
        close();
        setLoaded(false);
        const auth = getAuth();
        const user = auth.currentUser;
        await AddWithdrawAddress(user.uid, address);
        await getAddresses();
    }
 
    React.useEffect(()=>{
        getAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const isSmall = window.innerWidth < 768;
    const height = window.innerHeight - 100;
    
  return (
    <div className="content" style={
        {
            display: "flex",
            justifyContent: "center",
        }
    }
   >
    <Dialog
        isShown={showDialog}
        title="Add Address"
        onCloseComplete={() => setShowDialog(false)}
        confirmLabel="Add"
        onConfirm={addAddress}
     >
        <center>
            <TextInput id="address1" placeholder="Address" />
        </center>

    </Dialog>
    <NotificationAlert ref={notificationAlert} />
    <center>
        <Row>
            <Col md="12">
              { loaded? <MDBCard style={
                      isSmall ? 
                      {
                       marginTop: "40px",
                       height: "400px",
                       width: "90%",
                       justifySelf: "center",
                       alignSelf: "center",
                       justifyContent: "center",
                   }
                      :
                    {
                        marginTop: "40px",
                        height: "400px",
                        width: "400px",
                        justifySelf: "center",
                        alignSelf: "center",
                        justifyContent: "center",
                    }
                }>
                    <div style={
                        {
                            display: "flex",
                            position: "absolute",
                            right: "20px",
                            top: "20px",
                        }
                    }>
                        <Button onClick={()=>setShowDialog(true)}>
                            <i style={
                                {
                                    marginRight: "5px",
                                }
                            } className="fas fa-plus"></i> {"Add Address"}
                        </Button>
                    </div>
                    <div style={
                        {
                            display: "flex",
                            position: "absolute",
                            left: "20px",
                            top: "20px",
                        }
                    }>
                        <Button>
                            {"$" + balance}
                        </Button>
                    </div>
                 {loaded?   <MDBCardBody style={
                        {
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",

                        }
                    } className="p-4">
                       <div>
                       <MDBInput style={
                        {
                            marginTop: "40px",
                            height: "40px",
                        }
                       } wrapperClass='mb-4' label="amount($)" labelStyle={
                        {
                            color: "rgba(0, 0, 0, 0.4)",
                            fontSize: "14px",
                        }
                       } id="with1" type="number"/>
                      <center>
                      <SelectMenu
                        title="Select Address"
                        options={addresses.map((address)=>{
                            return {
                                label: address,
                                value: address,
                            }
                        })}
                        selected={selected}
                        closeOnSelect={true}
                        onSelect={(item)=>{
                            setSelected(item.value);
                        }}
                        emptyView={
                            <div style={{padding: "10px"}}>
                                <center>
                                    <p>No Addresses</p>
                                    <Button 
                                        onClick={()=>{
                                            setShowDialog(true);
                                        }}
                                    >
                                        Add Address
                                    </Button>
                                </center>
                            </div>}
                      >
                            <Button
                            style={
                                {
                                    marginBottom: "15px",
                                    maxWidth: "100%",
                                    overflow: "hidden",
                                }
                            }
                            >{selected||"select address"}</Button>
                      </SelectMenu>
                      </center>
                       </div>
                          <div  style={
                            {
                                position: "absolute",
                                bottom: "20px",
                                right: "20px",
                                left: "20px",
                            }
                          }>
                            <center>
                            <MDBBtn onClick={withdraw} >
                                {"withdraw"}
                            </MDBBtn>
                            </center>
                            </div>

                    </MDBCardBody>:<center><Spinner /></center>}
                    </MDBCard>
                    :<div
                    style={
                        {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: height,
                            flexDirection: "column",

                        }
                    }
                    ><center><Spinner size={80}/></center></div>}
            </Col>
        </Row>
    </center>
    </div>
  );
}

export default Withdraw;
