import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,Route, Switch } from "react-router-dom";
//import { createBrowserHistory } from "history";

import App from './App';
import * as serviceWorker from './serviceWorker';
import AdminLayout from "./Admin";
import Login from "./views/Login";
import Signup from "./views/Signup";
import c from "./const";
import Money from './views/Money';

//import './App.css';
import './assets/scss/style.scss';

//const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));
c();

root.render(
  <BrowserRouter>
  <Switch>
  <Route path="/home" render={(props) => <AdminLayout {...props} />} />
  <Route path="/login" render={(props) => <Login/>} />
  <Route path="/signup" render={(props) => <Signup/>} />
  <Route path="/money" render={(props) => <Money/>} />
  <Route path="/" render={(props) => <App {...props} />} />
  </Switch>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
