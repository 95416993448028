import React from "react";
import { MDBContainer,MDBCard, MDBCardBody,} from "mdb-react-ui-kit";
import {SelectMenu, Button, TextInput, Alert, toaster} from "evergreen-ui";
import { useLocation,useHistory } from "react-router-dom";
import {getAuth} from "firebase/auth"
import { Row, Col } from "reactstrap";
import { ProccessWithdrawal,} from "../utils/UserData";
import { sendMailForTransaction } from "../utils/Mailer";

const borderColor = "rgba(0, 0, 0, 0.2)";


function DepositMoney () {
    const [selected, setSelected] = React.useState(null);
    const [amount, setAmount] = React.useState(0);
    const [address, setAddress] = React.useState("");
    const [done, setDone] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const location = useLocation();
    const history = useHistory();
    const auth =  getAuth();
    auth.onAuthStateChanged((user) => {
        if (!user) {
            history.push("/login");
        }
    });
    React.useEffect(() => {
        const query = new URLSearchParams(location.search);
        const amount = query.get("amount") * 1;
        const address = query.get("address");
        if(amount > 0 && address != null){
            setAmount(amount);
            setAddress(address);
        }else{
            history.replace("/home/withdraw");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function withdraw() {
        setLoading(true);
        if(!selected){
            toaster.danger("Please select address coin");
            setLoading(false);
            return;
        }
        try {
            const uid = auth.currentUser.uid;
            await ProccessWithdrawal(uid, amount, address, selected);
            const email = auth.currentUser.email;
            const name = auth.currentUser.displayName;
            sendMailForTransaction(email, name, amount, "Withdraw", "Processing");
            setDone(true);
        } catch (error) {
            toaster.warning(error.message);
        }finally{
            setLoading(false);
        }
    }
 const isSmall = window.innerWidth < 768;
  return (
    <MDBContainer fluid style={{
        //expand to fill the screen
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        //center the content
        display: "flex",
        justifyContent: "center",
    }} className='p-3 background-radial-gradient overflow-hidden money'> 
      
            <div 
            style={{
                //center the content
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                //make the content responsive
                width: "100%",
                height: "100%",
            }}>
                <MDBCard style={
                isSmall ? {
                    width: "100%",
                    height: "70%",
                    }: {
                        width: "400px",
                        height: "600px",
                        }
                } className='my-5 ' >
                        <center>
                        <h3 className='black-text'>Secure Withdraw</h3>
                        </center>
                   { done ? <div style={
                    {
                        //center the content
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        height: "60%",
                    }
                   } className='text-center'>
                       <i className="fas fa-check-circle fa-5x green-icon"></i>
                        <Alert margin={30} intent="success" title="Your withdraw is been processed" >
                            </Alert>
                            <Button onClick={() => history.push("/home")} appearance="primary" intent="success" >Go Home</Button>
                            </div> : 
                   <MDBCardBody
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                    }}
                    >
                        <Row>
                            <Col>
                            <p className='black-text'>amount: </p>
                            </Col>
                            <Col>
                    <Alert style={
                        {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            textAlign: "right",
                            justifyContent: "center",
                        alignItems: "center",
                        borderColor: borderColor,
                        borderRadius: "5px",
                        
                        }
                       } intent="none" hasIcon={false} title={"$"+amount}  height={20}
                       marginBottom={20}
                       >
                            </Alert>
                            </Col>
                            </Row>
                            <Row>
                            <Col>
                            <p className='black-text'>address: </p>
                            </Col>
                            <Col>
                        {
                      
                                <TextInput 
                                readOnly
                                style={{
                                    width: "100%",
                                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                                    // add color to border
                                    borderColor: borderColor,
                                    borderRadius:"5px"
                                  }}
                                 value={address}>
                                 </TextInput>
                                
                        }
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                            <p className='black-text'>coin: </p>
                            </Col>
                            <Col>
                    <SelectMenu 
                    closeOnSelect={true}
                 title="Select Coin"
                 filterPlaceholder="Search Coins"
                 options={["BNB(bep20)","BTC","LTC","TRX","ETH", "BNB","USDT(trc20)","USDT(bep20)"
                ].map((label) => ({ label, value: label, }))}
                 selected={selected}
                 onSelect={(item) => {setSelected(item.value);
                }}
    >
      <Button style={{
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: borderColor,
        borderRadius: "5px",
      }}>{selected || 'Select Coin'}</Button>
    </SelectMenu>
                            </Col>
                            </Row>

                <center className="contact">
                    Having trouble? <a className="link" href="mailto:support@wackinvest.com"> contact support</a>
                </center>
                <center className="terms">
                    <p className="black-text">By clicking withdraw below, you agree to our <a className="link" href="/terms">Terms of Service</a> and <a className="link" href="/privacy">Privacy Policy</a></p>
                </center>
                <center>
                <Button style={{
                    width: "100%",
                    borderColor: borderColor,
                    borderRadius: "5px",
                }}
                intent="info"
                appearance="primary"
                isLoading={loading}
                 onClick={withdraw}>{loading?null:"Withdraw"}</Button>
                </center>
                </MDBCardBody>}
                </MDBCard>
                </div>
        </MDBContainer>
  );
}

export default DepositMoney;