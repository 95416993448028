import {useRef} from "react";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import {
    Row,
    Col
  } from "reactstrap";
  import {
      MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBInput,
  }
  from 'mdb-react-ui-kit';
  

function App() {
    const history = useHistory();
    const notificationAlert = useRef();

    async function deposit() {
        const b = document.getElementById("dep1").value * 1;
        if(!(b > 0)){
          document.getElementById("dep1").value = 0;
            return;
        }
        const url = "/money/deposit?amount=" + b;
        history.push(url);
    }
const isSmall = window.innerWidth < 768;
    
  return (
    <div className="content" style={
        {
            display: "flex",
            justifyContent: "center",
        }
    }
   >
    <NotificationAlert ref={notificationAlert} />
    <center>
        <Row>
            <Col md="12">
                <MDBCard style={
                   isSmall ? 
                   {
                    marginTop: "40px",
                    height: "400px",
                    width: "90%",
                    justifySelf: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                }
                   :{
                        marginTop: "40px",
                        height: "400px",
                        width: "400px",
                        justifySelf: "center",
                        alignSelf: "center",
                        justifyContent: "center",
                    }
                }>
                    <MDBCardBody style={
                        {
                            justifyContent: "center",
                        }
                    } className="p-4">
                       <div>
                       <MDBInput style={
                        {
                            marginTop: "40px",
                            height: "40px",
                        }
                       } wrapperClass='mb-4' label="amount($)" labelStyle={
                        {
                            color: "rgba(0, 0, 0, 0.4)",
                            fontSize: "14px",
                        }} id="dep1" type="number" />
                       </div>
                          <div style={
                            {
                                position: "absolute",
                                bottom: "20px",
                                right: "20px",
                                left: "20px",
                            }
                          }>
                            <center>
                            <MDBBtn onClick={deposit} >
                                {"Deposit"}
                            </MDBBtn>
                            </center>
                            </div>

                    </MDBCardBody>
                    </MDBCard>
            </Col>
        </Row>
        </center>
    </div>
  );
}

export default App;
