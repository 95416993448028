import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBQRbAQqUUR-Of-mfZu1dymCItuKL78yUE",
  authDomain: "meg-finance.firebaseapp.com",
  projectId: "meg-finance",
  storageBucket: "meg-finance.appspot.com",
  messagingSenderId: "715304172880",
  appId: "1:715304172880:web:0199582de8050e7050c055",
  measurementId: "G-NSEMBXCQYT"
  };

  // Initialize Firebase
initializeApp(firebaseConfig);
function App() {
 
}

export default App;