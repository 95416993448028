import {React, useState} from "react";
// react plugin used to create charts
import {TA,TapeFooter,BitcoinChart} from "../components/elements/TradeChart.js";
// import firestore
import {getAuth} from "firebase/auth"
import { GetUserData,UserData, GetReferralData, ReferralData } from "../utils/UserData.js";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import Chart from "../components/elements/MyChart.js";
import { Link } from "react-router-dom";
import { toaster } from "evergreen-ui";
// core components

function Dashboard() {
  const auth =  getAuth();
const [userData, setData] = useState(UserData.emtpy());
const [referralData, setReferralData] = useState(ReferralData.emtpy());
const [loaded, setLoaded] = useState(false);
async function  fetchData (uid) {
  console.log("fetched data");
  const data = await GetUserData(uid);  
  GetReferralData(uid).then((referralData) => {
    if(referralData){
      setReferralData(referralData);
    }
  });
  setData(data);
 if (data) {
   setLoaded(true);
 }else{
   fetchData(uid);
 }
}
function copyReferralLink() {
  var copyText = "https://meg-finance.org/signup?ref=" + userData.uid;
  navigator.clipboard.writeText(copyText);
  toaster.success("Copied referral link to clipboard");
}

auth.onAuthStateChanged((user) => {
if(user){
  if(!loaded){
    fetchData(user.uid);
  }
}
});
if(!loaded){
  return(
// center div 
<div className="content" style={
  {
    display: "flex",
    justifyContent: "center",
  }
}>
  <Col md="12">
    <center>
      <h1 style={
        {
          color: "#5658DD",
        }
      }>Preparing Dashboard..</h1>
    </center>
  </Col>
</div>
  );
}
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-spaceship text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Plan</p>
                      <CardTitle tag="p"> {userData.plan}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Link to={"/home/upgrade"}>
                <div className="stats">
                  <i className="fab fa-btc" /> Upgrade 
                </div>
                </Link>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Balance</p>
                      <CardTitle tag="p">${userData.balance}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Link to={"/home/deposit"}>
                <div className="stats">
                  <i className="fas fa-coins" /> Deposit
                </div>
                </Link>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Referrals</p>
                      <CardTitle tag="p">{
                      referralData.referredUsers.length + " Users"
                      }</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div 
                onClick={copyReferralLink}
                style={
                  {
                    cursor: "pointer",
                  }
                }
                 className="stats">
                  <i className="fas fa-copy" /> Copy Referral Link
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bank text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">withdrawable</p>
                      <CardTitle tag="p">${userData.balance}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Link to={"/home/withdraw"}>
                <div className="stats">
                  <i className="fas fa-wallet" /> Withdraw
                </div>
                </Link>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Trades</CardTitle>
                <p className="card-category">customer funds traded in realtime</p>
              </CardHeader>
              <CardBody>
                <Chart></Chart>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> realtime chart
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <Card>
              <CardBody>
              <TA></TA>
              </CardBody>
              </Card>
          </Col>
          <Col md="7">
            <BitcoinChart></BitcoinChart>
          </Col>
        </Row>
        <Row>
          <Col md="12">
              <TapeFooter></TapeFooter>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
