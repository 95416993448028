import React from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import DepositMoney from "./DepositMoney";
import WithdrawMoney from "./WithdrawMoney";


function Money() {
    return (
        <Switch>
        <Route path="/money/deposit" render={(props) => <DepositMoney {...props} />} />
        <Route path="/money/withdraw" render={(props) => <WithdrawMoney {...props} />} />
        <Redirect to="/money/deposit" />
        </Switch>
    );
}

export default Money;