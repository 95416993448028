
class Coin{
    constructor(name, symbol, address){
        this.name = name;
        this.symbol = symbol;
        this.address = address;
        this.price = 0;
    }
    static emtpy(){
        return new Coin("", "", "");
    }
}

const n = ["binancecoin","bitcoin","litecoin","tron","ethereum","binancecoin"];
const s = ["BNB(bep20)","BTC","LTC","TRX","ETH", "BNB"];
const a = [
"0x9408779Dc440E9da990f2FAA659D68EEE47912A3",
"bc1qp635dqkesfkuqmmzuscc2jq8dvv05kvg0yjk3g",
"ltc1q20ragnwhrsmcat9jter9lkgu5xv7ng9mkuz7kk",
"TV8V7crptDX1xanAWnGhkDgSdVvjtVzmXD",
"0x9408779Dc440E9da990f2FAA659D68EEE47912A3",
"bnb1j7h09jzl2raecrundj85a94uxmuhkgeqj52qsx"
];

function getCoins(prices){
    let coins = [];
    for(let i = 0; i < n.length; i++){
        const c = new Coin(n[i], s[i], a[i]);
        c.price = prices[n[i]].usd
        coins.push(c);
    }
    var usdt = new Coin("tether(bep20)", "USDT(bep20)", "0x9408779Dc440E9da990f2FAA659D68EEE47912A3");
    usdt.price = 1;
    coins.push(usdt);
    var usdt2 = new Coin("tether(trc20)", "USDT(trc20)", "TV8V7crptDX1xanAWnGhkDgSdVvjtVzmXD");
    usdt2.price = 1;
    coins.push(usdt2);

    coins.sort((a, b) => (a.symbol > b.symbol) ? -1 : 1);
    return coins;
}

export {Coin, getCoins};
